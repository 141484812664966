export default {
  save: 'Opslaan',
  from: 'van',
  till: 'tot',
  create: 'Create',
  update: 'Update',
  delete: 'Delete',
  'no-data': 'Er is niets gevonden...',
  'no-results': 'Er is niets gevonden...',
  'rows-per-page-text': 'Rijen per pagina',
  'snackbar-saved': 'Het is opgeslagen!',
  'snackbar-savee-rror': 'Er ging iets mis, probeer het later opnieuw',
  'snackbar-get-error': 'Er ging iets mis, probeer het later opnieuwr',
  'snackbar-deleted': 'Het is verwijderd!',
  'snackbar-delete-error': 'Er ging iets mis, probeer het later opnieuw',
  'search': 'Zoeken',
};
