<template>
  <div class="page page-documents ma-0 pa-0">
    <vuetify-resource
        :canAdd="false"
        :canDelete="false"
        :canSearch="true"
        :canUpdate="false"
        :getDataCallback="getDataFromApi"
        :showSpeedDail="false"
        :tableContent="tableContent"
        :texts="require('../VuetifyResourceTexts.js').default"
        ref="resource"
    >
      <template slot="crudActionsAfter" slot-scope="slotProps">
        <v-tooltip left>
          <template #activator="{ on }">
            <v-btn
                @click="downloadDocument(slotProps.resource)"
                color="accent"
                text
                icon
                v-on="on"
            >
              <v-icon>far fa-download</v-icon>
            </v-btn>
          </template>
          <span>Downloaden</span>
        </v-tooltip>
      </template>
    </vuetify-resource>
  </div>
</template>

<script>
import downloadableDocuments from '../mixins/downloadableDocuments.js';

export default {
  name: 'Documents',
  mixins: [downloadableDocuments],
  data() {
    return {
      errors: [],
      tableContent: this.getTableContents(),
    };
  },
  methods: {
    getDataFromApi(pagination, search) {
      const { sortBy, sortDesc, page, itemsPerPage } = pagination;
      return new Promise((resolve, reject) => {
        const url = 'navigation/' + this.$route.params.navigationId + '/document';
        const params = {
          q: search,
          page: page,
          perPage: itemsPerPage,
        };

        if (sortBy.length) {
          params.page = page;
          params.desc = sortDesc[0] ? 1 : 0;
        }
        this.$http.get(url, {
              params,
            })
            .then((response) => {
              let items = response.data.data.map(obj => {
                return {
                  id: obj.id,
                  name: obj.file.name,
                  extension: obj.file.extension,
                  created_at: this.$moment(obj.created_at).format('LLL'),
                  download_path: obj.download_path,
                };
              });
              let total = response.data.meta.total;
              resolve({
                items,
                total,
              });
            }).catch(() => reject());

      });
    },
    getTableContents() {
      let table = [];
      table.push(
          {
            text: 'Naam',
            align: 'left',
            sortable: true,
            value: 'name',
          }, {
            text: 'Datum',
            align: 'left',
            sortable: true,
            value: 'created_at',
          },
      );
      return table;
    },
  },
};
</script>

<style scoped>

</style>
