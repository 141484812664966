export default {
  methods: {
    downloadDocument(document) {
      let name = null;
      let extension = null;

      if ('file' in document) {
        name = document.file.name;
        extension = document.file.extension;
      } else {
        name = document.name;
        extension = document.extension;
      }

      this.downloadFile(document.download_path, name + '.' + extension, 'GET');
    },
    downloadFile(url, fileName, method) {
      this.$http({
        url: url,
        method: method,
        responseType: 'blob', // important
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = window.document.createElement('a');
        link.href = url;
        link.target = '_blank';
        link.setAttribute('download', fileName);
        window.document.body.appendChild(link);
        link.click();
      });
    },
  },
};

